.brands {
  min-height: size(728px);
  padding-top: size(100px);
  padding-bottom: size(77px);
  background: $color-alice-blue;

  @include vp--1023 {
    min-height: 0;
    padding-top: 62px;
    padding-bottom: 46px;
  }

  &__title {
    margin: 0 0 size(31px);

    @include vp--1023 {
      margin-bottom: 17px;
    }
  }
}
