.main-nav {
  @include vp--767 {
    display: none;
  }

  &__list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
  }


  &__item {
    margin-right: size(32px);
    padding: size(8px) 0;

    &:last-child {
      margin-right: 0;
    }
  }

  &__link {
    color: inherit;
    transition: color $trans--300;

    @include hover {
      color: $color-primary;
    }
  }
}
