.feedback {
  padding: size(100px) 0;
  // background-color: $color-alice-blue;
  background-color: $color-default-white;

  @include vp--1023 {
    padding-top: 60px;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include vp--1023 {
      flex-direction: column;
    }
  }

  &__title {
    margin: 0;
    margin-right: size(30px);

    @include vp--1023 {
      margin-right: 0;
      margin-bottom: 40px;
    }
  }

  &__btn {
    &:hover {
      svg {
        transform: rotate(0deg);
      }
    }
  }
}
