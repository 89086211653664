@mixin retina {
  @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
    @content;
  }
}

// Desktop first

@mixin vp--1919 {
  @media (max-width: $vp--1920 - 1) {
    @content;
  }
}

@mixin vp--1535 {
  @media (max-width: $vp--1536 - 1) {
    @content;
  }
}

@mixin vp--1439 {
  @media (max-width: $vp--1440 - 1) {
    @content;
  }
}

@mixin vp--1279 {
  @media (max-width: $vp--1280 - 1) {
    @content;
  }
}

@mixin vp--1023 {
  @media (max-width: $vp--1024 - 1) {
    @content;
  }
}

@mixin vp--767 {
  @media (max-width: $vp--768 - 1) {
    @content;
  }
}

@mixin vp--374 {
  @media (max-width: $vp--375 - 1) {
    @content;
  }
}

@mixin vp--359 {
  @media (max-width: $vp--360 - 1) {
    @content;
  }
}

// Mobile first

// @mixin vp--375 {
//   @media (min-width: $vp--375) {
//     @content;
//   }
// }

// @mixin vp--768 {
//   @media (min-width: $vp--768) {
//     @content;
//   }
// }

@mixin vp--1024 {
  @media (min-width: $vp--1024) {
    @content;
  }
}

@mixin vp--1280 {
  @media (min-width: $vp--1280) {
    @content;
  }
}

// @mixin vp--1440 {
//   @media (min-width: $vp--1440) {
//     @content;
//   }
// }

// @mixin vp--1920 {
//   @media (min-width: $vp--1920) {
//     @content;
//   }
// }

@mixin hover {
  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      @content;
    }
  }
}

@mixin hover-parent($parent) {
  @media (hover: hover), screen and (min-width: 0\0) {
    #{$parent}:hover &,
    #{$parent}:focus & {
      @content;
    }
  }
}

@mixin h2 {
  font-family: $font-family--montserrat;
  font-weight: 600;
  font-size: size(40px);
  line-height: 110%;
  color: $color-primary-dark-blue;

  @include vp--1023 {
    font-size: 24px;
  }
}
