.footer {
  background-color: $color-link-water;

  &__wrapper {
    display: flex;
    // min-height: size(434px);
    font-weight: 600;
    font-size: size(14px);
    line-height: size(22px);

    @include vp--1023 {
      flex-direction: column;
    }
  }

  &__left {
    padding-top: size(94px);
    padding-right: size(50px);
    padding-bottom: size(86px);
    width: 32.2%;
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    color: $color-default-white;
    background-color: $color-primary-gray;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 100%;
      left: -125%;
      bottom: 0;
      background-color: $color-primary-gray;
    }

    @include vp--1535 {
      flex-grow: 0;
      flex-shrink: 0;
    }

    @include vp--1023 {
      padding-top: 39px;
      padding-bottom: 24px;
      width: 100%;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: -125%;
        left: 100%;
        bottom: 0;
        background-color: $color-primary-gray;
      }
    }
  }

  &__logo {
    margin-bottom: size(64px);
    display: flex;
    color: inherit;
    transition: opacity $trans--300;

    @include hover {
      &[href] {
        opacity: 0.7;
      }
    }

    @include vp--1023 {
      margin-bottom: 37px;
    }

    svg {
      width: size(98px);
      height: size(38px);

      @include vp--1023 {
        width: size(71px);
        height: size(28px);
      }
    }
  }

  &__left-list,
  &__right-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__left-list {
    margin-top: auto;
    display: flex;
    flex-direction: column;

    @include vp--1023 {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  &__left-item {
    margin-bottom: size(8px);

    &:last-child {
      margin-bottom: 0;
    }

    @include vp--1023 {
      margin-right: 35px;
    }
  }

  &__left-link {
    width: max-content;
    display: flex;
    align-items: center;
    color: inherit;
    transition: color $trans--300;

    @include hover {
      color: $color-primary;
    }

    svg {
      margin-right: size(8px);
      width: size(22px);
      height: size(22px);
      order: -1;
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    padding-top: size(101px);
    // padding-right: size(110px);
    padding-bottom: size(102px);
    padding-left: size(145px);
    width: 67.8%;
    flex-grow: 1;
    color: $color-primary-gray;
    background-color: inherit;

    @include vp--1535 {
      padding-right: 0;
      padding-left: 50px;
    }

    @include vp--1023 {
      width: 100%;
      padding-left: 0;
      padding-top: 24px;
      padding-bottom: 34px;
    }
  }

  &__right-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 25%;
    min-height: size(55px);
    margin-right: size(-47px);

    @include vp--767 {
      grid-template-columns: repeat(1, 1fr);
      margin-bottom: 18px;
      margin-right: 0;
    }
  }

  &__right-item {
    // margin-bottom: size(16px);
    // margin-right: size(40px);
    // min-width: 95px;
    // max-width: 325px;

    @include vp--1023 {
      margin-bottom: 8px;
    }

    @include vp--767 {
      min-width: auto;
      max-width: none;
    }
  }

  &__right-link {
    color: inherit;
    transition: color $trans--300;

    @include hover {
      color: $color-primary;
    }
  }

  &__copyright {
    margin-top: size(33px);
    display: flex;
    align-items: center;
    text-transform: uppercase;

    svg {
      margin-left: size(-8px);
      width: size(22px);
      height: size(22px);
      order: -1;
    }

    @include vp--767 {
      margin-top: 0;
    }
  }

  &__policy {
    font-weight: 400;
    font-size: size(12px);
    line-height: size(16px);
    color: $color-primary-gray;

    a {
      font-weight: 500;
      color: $color-primary-dark-blue;
      transition: color $trans--300;

      @include hover {
        color: $color-primary;
      }
    }
  }
}
