//$color-eucalyptus: #1d681d;

.custom-select {
  position: relative;

  width: size(183px);
  padding: size(6px) 0 size(30px);

  @include vp--1023 {
    width: 142px;
    padding: 17px 0 0;
  }

  &__label {
    position: absolute;
    top: 50%;
    left: size(10px);

    transform: translateY(-50%);

    transition: transform $trans--300, opacity $trans--300;

    pointer-events: none;
  }

  &__placeholder {
    position: absolute;
    top: 50%;
    left: size(10px);

    transform: translateY(-50%);

    transition: transform $trans--300, opacity $trans--300;

    pointer-events: none;
  }

  select {
    position: absolute;

    width: 1px;
    height: 1px;
    margin: -1px;

    clip: rect(0 0 0 0);
  }

  &__button {
    position: relative;
    display: flex;
    align-items: center;
    //width: 100%;
    height: size(40px);
    padding: size(5px) 0 size(5px) size(48px);

    background-color: $color-transparent;
    color: $color-primary-gray;
    border: none;
    border-radius: size(5px);
    outline: none;
    cursor: pointer;

    transition: border $trans--300, color $trans--300;

    @include hover {
      color: $color-primary-hover;
    }

    &:hover {
      //border: 2px solid rgba($color-default-black, 0.5);
    }

    &:focus {
      //border: 2px solid rgba($color-default-black, 0.8);
    }

    .custom-select__icon-img {
      left: size(16px);
      transform: translateY(calc(-50% - #{size(2px)}));

      @include vp--1023 {
        transform: translateY(-50%);
      }
    }
  }

  &__list {
    position: absolute;
    bottom: size(32px);
    z-index: 1;

    width: 100%;
    //max-height: 70vh;
    margin: 0;
    padding: size(18px) size(16px);
    overflow-y: auto;

    list-style: none;
    font-family: $font-family--montserrat;
    font-weight: 600;
    font-size: size(14px);
    line-height: size(22px);
    color: $color-primary-gray;

    background-color: $color-default-white;
    border-radius: size(4px);
    transform: translateY(100%);
    visibility: hidden;
    opacity: 0;

    transition: visibility $trans--300, opacity $trans--300;
    filter: drop-shadow(0 size(5px) size(16px) rgba($color-spindle, 0.32));

    @include vp--1023 {
      bottom: 0;
      //height: 200px;
      //max-height: 60vh;
    }
  }

  &__item {
    position: relative;
    margin-bottom: size(4px);
    padding: size(5px) size(5px) size(5px) size((48px - 16px));
    outline: none;
    cursor: pointer;
    transition: color $trans--300;

    &[aria-selected="true"] {
      color: $color-primary;

      @include hover {
        color: $color-primary-hover;
      }
    }

    @include hover {
      color: $color-primary-hover;
    }
  }

  &__icon-img {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: size(24px);
    height: size(24px);
    border-radius: size(4px);

    img {
      position: relative;
      z-index: -1;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: size(4px);
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      box-shadow: inset 0 0 0 1px rgba($color-primary-dark-blue, 0.3);
      border-radius: size(4px);
    }
  }

  &__error {
    position: absolute;
    bottom: 0;
    left: 0;
    display: none;
    color: $color-primary;

    opacity: 0;

    transition: opacity 0.1s ease;
  }

  &__text {
    flex-shrink: 0;
    //width: 80%;
    overflow: hidden;

    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;

    opacity: 0;

    transition: opacity $trans--300 0.1s;

    font-family: $font-family--montserrat;
    font-weight: 600;
    font-size: size(20px);
    line-height: size(28px);

    @include vp--1023 {
      font-size: 14px;
      line-height: 22px;
    }

    br {
      display: none;
    }
  }

  &__icon {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: size(24px);
    height: size(24px);
    margin-left: auto;
    transition: transform $trans--300;

    svg {
      transition: transform $trans--300;
    }
  }

  &.open-up {
    .custom-select__list {
      bottom: auto;
      top: 0;
    }
  }

  &.is-open {
    .custom-select__list {
      visibility: visible;
      opacity: 1;
    }

    .custom-select__icon {
      svg {
        transform: rotate(180deg);
      }
    }

    .custom-select__label {
      opacity: 0;
    }
  }

  &.not-empty {
    .custom-select__text {
      opacity: 1;
    }

    .custom-select__label {
      opacity: 0;
    }

    .custom-select__placeholder {
      opacity: 0;
    }
  }

  &.is-invalid {
    .custom-select__error {
      opacity: 1;
    }

    .custom-select__button {
      //border: 2px solid $color-fire-brick;

      &:focus,
      &:hover {
        //border: 2px solid rgba($color-fire-brick, 0.5);
      }
    }
  }

  &.is-valid {
    .custom-select__button {
      //border: 2px solid $color-eucalyptus;

      &:focus,
      &:hover {
        //border: 2px solid rgba($color-eucalyptus, 0.5);
      }
    }
  }

  &--placeholder {
    .custom-select__label {
      transform: translateY(calc(-50% - #{size(40px)}));
      opacity: 1;
    }

    &.is-open .custom-select__label {
      opacity: 1;
    }

    &.not-empty .custom-select__label {
      opacity: 1;
    }
  }

  &.slide-up.not-empty,
  &.slide-up.is-open {
    .custom-select__label {
      transform: translateY(calc(-50% - #{size(40px)}));
      opacity: 1;
    }

    .custom-select__text {
      opacity: 1;
    }
  }
}
