.modal-cookie
{
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 10;
  display: inline-block;
  font-size: .87455rem;
  right: auto;
  top: auto;

  @include vp--767 {
    .modal__wrapper
    {
      padding: 1rem;
      .modal__content
      {

      }
    }
  }

  .modal__wrapper
  {
    padding: 2rem;
    .modal__content
    {
      max-width: 25.5rem;
      background-color: #fff;
      position: relative;
      border-radius: 4px;
      padding: 2.5rem 1.5rem 1.5rem;
      box-shadow: 0px 16px 28px rgba(0, 0, 0, 0.16);

      p
      {
        margin: 0 0 1.5rem;
        line-height: 1.38rem;
      }

      a.link
      {
        font-weight: 600;
        color: $color-primary-blue;
      }

      .modal__close-btn {
        position: absolute;
        top: size(15px);
        right: size(15px);

        padding: 0;

        background: $color-transparent;
        border: none;
        cursor: pointer;
      }
    }
  }

  .modal__close-btn
  {
    svg
    {
      fill: #8a98a7;
    }
  }
}

.modal-modern
{
  @include vp--767
  {
    .modal__wrapper
    {
      padding: 1rem 0 0;
      max-height: 100%;
      height: 100%;
      overflow: hidden;
      .modal__content
      {
        border-radius: 4px;
        padding: 0;
        position: relative;

        height: 100%;
        display: flex;
        flex-direction: column;

        .modal-modern-content
        {
          flex-grow: 1;
          height: 100%;
          overflow: scroll;
          order: 1;
          padding: .5rem 1rem;
          -webkit-overflow-scrolling: touch;
        }

        .modal-modern-top
        {
          flex-grow: 2;
          order: -1;
          padding: 2.5rem 1rem 1rem;
          box-shadow: 0px 5px 16px rgba(181, 195, 208, 0.32);

          & > *
          {
            margin: 0;
          }
        }

        .modal-modern-bottom
        {
          flex-grow: 2;
          order: 2;
          padding: 1rem;

          .btn--secondary
          {
            width: 100%;
            padding: 0.875rem 1.5rem;
          }
        }

      }

      .modal__swipedown
      {
        display: inline-block;
      }

    }
  }

  .modal__swipedown
  {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 30px;
    .modal__swipedown-btn
    {
      border: none;
      background: rgba(138, 152, 167, 0.5);
      width: 25px;
      height: 2px;

      position: absolute;
      top: 0.5rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }


  .modal__close-btn
  {
    z-index: 1;
    svg
    {
      fill: #8a98a7;
    }
  }
}
