.slider {
  &__wrapper {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    cursor: grab;

    img {
      object-fit: cover;
    }
  }

  &--brands {
    // overflow: hidden;
    margin: 0 size(-25px);
    padding: size(25px);

    @include vp--1023 {
      margin: 0 -16px;
      padding: 16px;
    }

    .slider {
      &__container {
        height: 100%;
        overflow: visible;
      }

      &__wrapper {
        margin-bottom: size(58px);

        @include vp--1023 {
          margin-bottom: 17px;
        }
      }

      &__item {
        display: flex;
        width: min-content;
        margin-right: size(24px);

        @include vp--1023 {
          margin: 0 size(24px / 2);
        }

        @include vp--767 {
          margin: 0 size(16px / 2);
        }
      }

      &__btns-wrapper {
        display: flex;
        margin-left: size(2px);

        @include vp--1023 {
          display: none;
        }
      }

      &__btn {
        &--next {
          margin-left: size(20px);
        }
      }

      &__pagination {
        display: none;

        @include vp--1023 {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          margin-bottom: -4px;
        }

        .swiper-pagination-bullet {
          display: none;

          @include vp--1023 {
            display: block;
            width: 8px;
            height: 8px;
            margin-bottom: 4px;
            background: $color-bali-hai;
            opacity: 1;
            transition: background-color $trans--300;
            flex-shrink: 0;

            &.swiper-pagination-bullet-active {
              background: $color-primary-dark-blue;
            }
          }
        }
      }
    }
  }
}
