.map-tooltip {
  width: size(316px);
  background-color: $color-default-white;
  border-radius: size(2px);
  box-shadow: 0 size(5px) size(16px) rgba($color-link-water, 0.32), 0 0 0 size(1px) $color-link-water;
  transition: min-height $trans--300;

  @include vp--1023 {
    width: 100%;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: 4;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    min-height: size(5px);
    width: size(20px);
    background-color: $color-default-white;
  }

  &__form {
    position: relative;
    display: none;

    @include vp--1023 {
      display: block;
      z-index: 3;
      padding-left: 7px;
    }
  }

  &__inner {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    min-height: size(100px);
    padding: size(12px) size(16px) size(8px);
    visibility: hidden;
    opacity: 0;
    transition: opacity $trans--300, visibility $trans--300;

    &.active {
      z-index: 2;
      visibility: visible;
      opacity: 1;
    }

    @include vp--1023 {
      padding: 27px 24px 39px;
    }
  }

  &__corner {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, size(5.6px)) rotate(45deg);
    transform-origin: center center;
    width: size(11.32px);
    height: size(11.32px);
    border: size(1px) solid $color-link-water;
    border-top-color: $color-transparent;
    border-left-color: $color-transparent;
    background-color: $color-default-white;
    box-shadow: 0 size(5px) size(16px) rgba($color-link-water, 0.32);

    @include vp--1023 {
      display: none;
    }
  }

  &__country {
    margin: 0 0 size(8px);
    font-family: $font-family--montserrat;
    font-weight: 600;
    font-size: size(20px);
    line-height: size(28px);
    color: $color-primary-dark-blue;

    @include vp--1023 {
      display: none;
    }
  }

  a {
    transition: color $trans--300;

    @include hover {
      color: $color-primary-hover;
    }
  }

  &__text {
    display: block;
    margin: 0;
    font-family: $font-family--montserrat;
    font-weight: 400;
    font-size: size(12px);
    line-height: size(16px);
    color: $color-primary-dark-blue;

    @include vp--1023 {
      max-width: 84%;
      // margin-bottom: 8px;
    }

    @include vp--359 {
      width: 233px;
      max-width: 100%;
    }
  }

  &__site {
    position: relative;
    display: inline-block;
    padding-right: size(30px);
    margin-top: size(12px);
    font-family: $font-family--montserrat;
    font-weight: 600;
    font-size: size(14px);
    line-height: size(22px);
    color: $color-primary-blue;

    @include vp--1023 {
      padding-right: 0;
      padding-left: 28px;
    }
  }

  &__site-icon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: size(20px);
    height: size(20px);

    @include vp--1024 {
      &--mob {
        display: none;
      }
    }

    @include vp--1023 {
      &--desk {
        display: none;
      }

      &--mob {
        right: auto;
        left: 0;
      }
    }
  }
}
