.about-accordion {
  max-width: size(936px);
  font-weight: 600;
  font-size: size(16px);
  line-height: size(24px);

  @include vp--1023 {
    font-size: 14px;
    line-height: 22px;
  }

  // & [data-accordion="content"] {
  //   max-height: none;

  //   @include vp--1023 {
  //     max-height: 0;
  //   }
  // }

  &__item {
    &.is-active {
      .about-accordion__content {
        display: block;
      }

      .about-accordion__btn {
        .show {
          display: none;
        }

        .hide {
          display: block;
        }
      }
    }
  }

  &__content {
    // @include vp--1023 {
    //   display: -webkit-box;
    //   -webkit-line-clamp: 4;
    //   -webkit-box-orient: vertical;
    //   // margin-bottom: 8px;
    // }
  }

  &__btn {
    // display: none;

    // @include vp--1023 {
      margin: 0;
      padding: 0;
      display: flex;
      align-self: flex-start;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: size(14px);
      line-height: size(22px);
      color: $color-primary-blue;
      background-color: $color-transparent;
      border: none;
      cursor: pointer;

      .show {
        display: block;
      }

      .hide {
        display: none;
      }
    // }
  }

  &__text {
    margin-top: 0;
    margin-bottom: size(16px);
    color: $color-primary-gray;

    // &:last-child {
    //   margin-bottom: 0;
    // }

    @include vp--1023 {
      margin-bottom: 8px;
    }
  }
}
