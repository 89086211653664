@keyframes down {
  0% {
    transform: translateY(-50%);
    opacity: 0;
  }

  10% {
    opacity: 0.9;
  }

  90% {
    opacity: 0;
  }

  100% {
    transform: translateY(40%);
    opacity: 0;
  }
}
