.intro-tabs {
  width: 100%;
  border-radius: size(4px);
  overflow: hidden;

  @include vp--1279 {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    border-radius: 0;
    overflow-x: scroll;
    overflow-y: visible;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    position: relative;
    display: grid;
    grid-template-columns: size(290px) size(290px);
    grid-template-rows: size(288px) size(288px);
    border-radius: 4px;
    overflow: hidden;
    list-style: none;
    z-index: 1;

    @include vp--1535 {
      grid-template-columns: 230px 230px;
      grid-template-rows: 230px 230px;
    }

    @include vp--1279 {
      display: flex;
      padding: 0 0 0 60px;
      flex-shrink: 0;
      transition: transform $trans--300;

      &::after {
        content: '';
        display: block;
        width: 60px;
        flex-shrink: 0;
      }
    }

    @include vp--1023 {
      padding: 0 0 0 24px;

      &::after {
        width: 24px;
      }
    }

    @include vp--767 {
      padding: 0 0 0 16px;

      &::after {
        width: 16px;
      }
    }
  }

  &__item {
    padding: size(32px);
    position: relative;
    background-color: rgba($color-default-white, 0.85);
    cursor: pointer;

    @include vp--1279 {
      padding: 16px;
      width: 100%;
      width: 200px;
      min-height: 147px;
      flex-shrink: 0;

      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &:last-child {
        // margin-right: 24px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        margin-right: 0;
      }
    }

    @include vp--767 {
      width: 158px;
      min-height: 116px;
      padding-bottom: 13px;

      // &:last-child {
      //   margin-right: 0;
      // }
    }

    &::before {
      content: "";
      position: absolute;
      top: 100%;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      will-change: top;
      transition: top $trans--300;
    }

    &.active {
      &::before {
        top: 0;
      }
    }

    &:nth-child(4n-3) {
      background-color: rgba($color-default-white, 0.8);

      &.active {
        &::before {
          background-color: $color-default-white;
        }
      }

      .intro-tabs__count {
        color: $color-primary;
      }

      .intro-tabs__text {
        color: $color-primary-dark-blue;
      }
    }

    &:nth-child(4n-2) {
      background-color: rgba($color-primary-dark-blue, 0.8);

      &.active {
        &::before {
          background-color: $color-primary-dark-blue;
        }
      }

      .intro-tabs__count {
        color: $color-primary;
      }

      .intro-tabs__text {
        color: $color-default-white;
      }
    }

    &:nth-child(4n-1) {
      background-color: rgba($color-primary, 0.8);

      &.active {
        &::before {
          background-color: $color-primary;
        }
      }

      .intro-tabs__count {
        color: $color-default-white;
      }

      .intro-tabs__text {
        color: $color-default-white;
      }
    }

    &:nth-child(4n) {
      background-color: rgba($color-primary-gray, 0.8);

      &.active {
        &::before {
          background-color: $color-primary-gray;
        }
      }

      .intro-tabs__count {
        color: $color-default-white;
      }

      .intro-tabs__text {
        color: $color-default-white;
      }
    }
  }

  &__count {
    margin-bottom: size(24px);
    font-weight: bold;
    font-size: size(60px);
    line-height: 110%;
    word-break: break-all;

    @include vp--1535 {
      font-size: 44px;
    }

    @include vp--1279 {
      font-size: 42px;
    }

    @include vp--767 {
      margin-bottom: 8px;
      font-size: 28px;
    }
  }

  &__text {
    width: min-content;
    font-weight: 600;
    font-size: size(16px);
    line-height: size(20px);

    @include vp--1535 {
      font-size: 14px;
    }

    @include vp--767 {
      font-size: 12px;
      line-height: 16px;
    }
  }
}
