.btn-block {
  margin: 0;
  padding: size(26px) size(24px);
  width: size(290px);
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  font-weight: 600;
  font-size: size(14px);
  line-height: size(22px);
  color: $color-default-white;
  text-decoration: none;
  background-color: $color-primary;
  border-radius: size(4px);
  overflow: hidden;
  border: none;
  cursor: pointer;

  @include vp--1023 {
    padding: size(11px) size(16px);
    max-width: 396px;
    width: 100%;
  }

  &--hover-lr {
    &::before {
      right: 100%;
      left: 0;
    }
  }

  &--hover-rl {
    &::before {
      right: 0;
      left: 100%;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: $color-primary-dark-blue;
    z-index: 1;
    transition: left $trans--300, right $trans--300;
  }

  &:hover {
    &::before {
      right: 0;
      left: 0;
    }
  }

  &__text {
    width: min-content;
    position: relative;
    z-index: 2;
  }

  &__icon {
    position: relative;
    z-index: 2;
  }
}
