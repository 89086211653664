// Vendor
//---------------------------------

@import "vendor/normalize";
@import "vendor/swiper";

// Global
//---------------------------------

@import "variables";
@import "functions";
@import "keyframes";
@import "mixins";
@import "global/fonts";
@import "global/reboot";
@import "global/utils";
@import "global/container";
@import "global/data-accordion";

// Blocks
//---------------------------------

@import "blocks/btn-block";
@import "blocks/btn";
@import "blocks/header";
@import "blocks/main-nav";
@import "blocks/footer";
@import "blocks/modal";
@import "blocks/modal-custom";
@import "blocks/slider";
@import "blocks/intro";
@import "blocks/intro-tabs";
@import "blocks/about";
@import "blocks/about-accordion";
@import "blocks/brands";
@import "blocks/brand-card";
@import "blocks/where-buy";
@import "blocks/custom-select";
@import "blocks/map-tooltip";
@import "blocks/country";
@import "blocks/feedback";


// используйте это для отключения ховеров на тач устройствах
// ! но не используйте это для текстовых полей ( input, textarea )
// также для любых transition обязательно указывайте transition-property
// transition: color $default-transition-settings, а не transition: $default-transition-settings

/* @media (hover: hover), screen and (min-width: 0\0) {
  &:hover,
  &:focus {
    color: red;
  }
} */
