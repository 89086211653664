.where-buy {
  position: relative;
  z-index: 10;
  //padding: size(100px) 0 size(93px);

  @include vp--1023 {
    //padding: 61px 0 60px;
  }

  &__inner {
    padding-top: size(100px);
    padding-bottom: size(93px);

    @include vp--1023 {
      padding-top: 61px;
      padding-bottom: 60px;
    }

    @include vp--767 {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }

  &__title {
    margin: 0;

    @include vp--1023 {
      margin-bottom: 31px;
    }
  }

  &__form {
    margin-bottom: size(70px);

    @include vp--1023 {
      margin-bottom: 0;
    }
}

  &__map {
    position: relative;
    margin: 0 size(-5px);

    @include vp--1023 {
      margin: 0;
    }

    > img {
      visibility: hidden;
      opacity: 0;
    }

    > svg {
      display: block;
      width: 100%;
      height: auto;
      visibility: visible;
      opacity: 1;

      @include vp--1023 {
        display: none;
      }
    }
  }

  &__tooltip {
    position: absolute;
    top: 0;
    left: 0;
    transition: transform $trans--300, visibility $trans--300;
    will-change: transform;

    .where-buy__map:not(.is-init) & {
      transition: visibility $trans--300;
      visibility: hidden;
      opacity: 0;
    }

    @include vp--1023 {
      position: relative;
      right: 0;
      width: 100%;
    }
  }
}
