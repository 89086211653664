// @font-face {
//   font-style: normal;
//   font-weight: 400;
//   font-family: "Rouble";

//   font-display: swap;
//   src:
//     url("../fonts/rouble.woff2") format("woff2"),
//     url("../fonts/rouble.woff") format("woff");
// }

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Montserrat";

  font-display: swap;
  src:
    url("../fonts/montserrat-v18-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff2") format("woff2"),
    url("../fonts/montserrat-v18-latin-ext_latin_cyrillic-ext_cyrillic-regular.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: "Montserrat";

  font-display: swap;
  src:
    url("../fonts/montserrat-v18-latin-ext_latin_cyrillic-ext_cyrillic-500.woff2") format("woff2"),
    url("../fonts/montserrat-v18-latin-ext_latin_cyrillic-ext_cyrillic-500.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-family: "Montserrat";

  font-display: swap;
  src:
    url("../fonts/montserrat-v18-latin-ext_latin_cyrillic-ext_cyrillic-600.woff2") format("woff2"),
    url("../fonts/montserrat-v18-latin-ext_latin_cyrillic-ext_cyrillic-600.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-family: "Montserrat";

  font-display: swap;
  src:
    url("../fonts/montserrat-v18-latin-ext_latin_cyrillic-ext_cyrillic-700.woff2") format("woff2"),
    url("../fonts/montserrat-v18-latin-ext_latin_cyrillic-ext_cyrillic-700.woff") format("woff");
}
