.btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  flex-shrink: 0;
  text-decoration: none;
  user-select: none;
  border: none;
  background: none;
  cursor: pointer;

  &--svg-reverse svg {
    transform: rotate(180deg);
  }

  &--svg-reverse-45 svg {
    transform: rotate(-45deg);
  }

  &--svg-reverse-90 svg {
    transform: rotate(90deg);
  }

  &--secondary
  {
    background: #dc0000;
    color: #fff;
    padding: 0.5rem 1.5rem;
    border-radius: 4px;
    font-weight: 600;
    line-height: 1.5rem;
  }

  &--round {
    width: size(48px);
    height: size(48px);
    border-radius: 50%;
    color: $color-default-white;
    transition: background-color $trans--300, box-shadow $trans--300, transform $trans--300;

    @include hover-parent(".btn-block") {
      &::after {
        transform: scale(1);
      }
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      transition: background-color $trans--300, transform $trans--300;
    }

    &::before {
      background-color: $color-primary-dark-blue;
    }

    &::after {
      background-color: $color-primary;
      transform: scale(0);
    }

    &:hover {
      &:not([aria-disabled="true"]) {
        &::after {
          transform: scale(1);
        }
      }
    }

    &.hovered {
      &::after {
        transform: scale(1);
      }
    }

    &[aria-disabled="true"] {
      cursor: default;

      svg {
        color: $color-bali-hai;
      }
    }

    svg {
      position: relative;
      display: block;
      width: size(24px);
      height: size(24px);
      transition: color $trans--300, transform $trans--300;
    }

    .btn__text {
      position: absolute;
      width: 1px;
      height: 1px;
      margin: -1px;
      clip: rect(0 0 0 0);
    }

    &.btn--slider {
      @include hover {
        &::after {
          transform: scale(calc(52 / 48));
        }
        //transform: scale(calc(52 / 48));
      }
    }
  }
}
