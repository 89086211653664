.header {
  padding-top: size(22px);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  color: $color-default-white;
  background-color: $color-transparent;
  transition: color $trans--300, background-color $trans--300;
  z-index: 100;

  @include vp--767 {
    padding-top: 0;
    background-color: rgba($color-primary-dark-blue, 0.5);
  }

  &--fixed {
    padding-top: 0;
    position: fixed;
    color: $color-primary-dark-blue;
    background-color: $color-default-white;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    min-height: size(80px);
    font-style: normal;
    font-weight: 500;
    font-size: size(14px);
    line-height: size(22px);

    @include vp--767 {
      min-height: 60px;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    margin-right: auto;
    padding-right: size(60px);
    flex-shrink: 0;
    color: $color-primary;
    transition: opacity $trans--300;

    @include hover {
      &[href] {
        opacity: 0.7;
      }
    }

    svg {
      width: size(87px);
      height: size(34px);
    }

    @include vp--767 {
      svg {
        width: 64px;
        height: 24px;
      }
    }
  }
}
