.brand-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: size(336px);
  height: size(320px);
  padding: size(32px);
  background-color: $color-default-white;
  border: size(1px) solid $color-alice-blue;
  border-radius: size(4px);
  transition: box-shadow $trans--300;
  overflow: hidden;

  @include hover {
    box-shadow: 0 size(14px) size(25px) rgba($color-spindle, 0.4);
  }

  @include vp--1023 {
    width: 294px;
    height: 303px;
  }

  .swiper-slide-active & {
    @include vp--1023 {
      box-shadow: 0 size(14px) size(25px) rgba($color-spindle, 0.4);
    }
  }

  &__img {
    position: relative;
    z-index: 2;
    display: block;
    // width: size(272px);
    width: 100%;
    height: size(77px + 32px * 2);
    padding: size(32px) 0;
    transform: translateY(#{size((110px) / 2)});
    transition: transform $trans--300;

    @include hover-parent(".brand-card") {
      // transform: scale((240 / 200));
      transform: translateY(0);
    }

    @include vp--1023 {
      padding-bottom: 0;
      height: size(77px + 32px);
      transform: none;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &__text-wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: size(110px);
    margin-top: size(7px);
    transform: translateY(100%);
    opacity: 0;
    transition: opacity $trans--300, transform $trans--300;
    overflow: hidden;

    @include hover-parent(".brand-card") {
      opacity: 1;
      transform: translateY(0);
    }

    @include vp--1023 {
      height: 132px;
      margin-top: 0;
      opacity: 1;
      transform: none;
    }
  }

  &__text {
    display: -webkit-box;
    margin: 0;
    font-size: size(14px);
    line-height: size(22px);
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    @include vp--1023 {
      -webkit-line-clamp: 6;
    }
  }

  // &__video {
  //   position: absolute;
  //   z-index: 1;
  //   opacity: 0;
  //   overflow: hidden;
  //   width: 100%;
  //   height: 100%;
  //   border-radius: size(4px);
  //   transition: opacity $trans--300, transform $trans--300;

  //   @include vp--1023 {
  //     .swiper-slide-active & {
  //       opacity: 1;
  //       display: block;
  //     }
  //   }

  //   video {
  //     width: 100%;
  //     height: 100%;
  //     object-fit: cover;
  //   }

  //   @include hover-parent(".brand-card--video") {
  //     opacity: 1;
  //     display: block;
  //   }
  // }
}
