.intro {
  --ios-fix-vh: calc(100 * var(--vh, 1vh));

  padding-top: size(206px);
  padding-bottom: size(108px);
  min-height: var(--screen-height, var(--ios-fix-vh));
  position: relative;
  transition: all 0.5s;

  @include vp--1535 {
    padding-top: 140px;
    padding-bottom: 50px;
  }

  @include vp--1279 {
    display: flex;
  }

  @include vp--767 {
    padding-top: 118px;
    padding-bottom: 20px;
  }

  @include vp--359 {
    padding-top: 90px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(180deg, $color-primary-dark-blue 0%, rgba($color-primary-dark-blue, 0) 81%);
    z-index: 1;

    @include vp--1279 {
      background-image:
        linear-gradient(180deg, $color-primary-dark-blue 0%, rgba($color-primary-dark-blue, 0) 81%),
        linear-gradient(103deg, rgba($color-primary-dark-blue, 0.21) 0%, rgba($color-primary-dark-blue, 0.17) 31.77%, rgba($color-primary-dark-blue, 0.77) 100%);
    }
  }

  &__wrapper {
    position: relative;
    display: grid;
    grid-template-columns: 1fr max-content;
    column-gap: size(70px);
    grid-template-areas:
      "content tabs"
      "btn tabs";
    z-index: 2;

    @include vp--1279 {
      padding: 0;
      display: flex;
      flex-direction: column;
      column-gap: 0;
    }

    @include vp--1023 {
      padding: 0;
    }

    @include vp--767 {
      padding: 0;
    }
  }

  &__slider {
    margin: 0;
    max-width: size(766px);
    flex-shrink: 0;
    grid-area: content;

    @include vp--1279 {
      max-width: none;
      padding: 0 60px;
    }

    @include vp--1023 {
      padding: 0 24px;
    }

    @include vp--767 {
      padding: 0 16px;
    }
  }

  &__title {
    width: min-content;
    margin: 0;
    margin-bottom: size(32px);
    font-weight: 600;
    font-size: size(60px);
    line-height: 110%;
    color: $color-default-white;

    @include vp--1535 {
      font-size: 48px;
    }

    @include vp--1023 {
      font-size: 42px;
    }

    @include vp--767 {
      margin-bottom: 16px;
      font-size: 28px;
    }
  }

  &__text {
    max-width: size(539px);
    margin: 0;
    margin-bottom: size(32px);
    font-weight: 600;
    font-size: size(24px);
    line-height: 150%;
    color: $color-default-white;

    @include vp--1535 {
      font-size: 18px;
    }

    @include vp--1279 {
      margin-bottom: 16px;
    }

    @include vp--1023 {
      font-size: 16px;
    }

    @include vp--767 {
      font-size: 14px;
      line-height: 22px;
    }
  }

  &__btn-wrap {
    margin-top: auto;
    grid-area: btn;
    display: flex;
    flex-direction: column;

    @include vp--1279 {
      margin-top: 0;
      padding: 0 60px;
      padding-top: 30px;
    }

    @include vp--1023 {
      margin-top: 30px;
      padding: 0 24px;
      padding-top: 30px;
    }

    @include vp--767 {
      padding: 0 16px;
      padding-top: 30px;
    }
  }

  &__btn {
    &:hover {
      svg {
        transform: rotate(0deg);
      }
    }
  }

  &__btn-scroll {
    display: none;

    @include vp--767 {
      margin-top: 39px;
      align-self: center;
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: $color-default-white;

      svg {
        animation: down 1.4s infinite linear;
      }
    }
  }

  &__tabs {
    grid-area: tabs;

    @include vp--1279 {
      margin-top: 30px;
      // padding: 0 0 0 60px;

      // &::after {
      //   content: '';
      //   display: block;
      //   width: 60px;
      //   flex-shrink: 0;
      // }
    }
  }

  &__background {
    z-index: -1;
    user-select: none;
  }

  &__picture {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    transition: opacity $trans--600;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &--main {
      opacity: 1;

      @include vp--1279 {
        &.mobile-hidden {
          opacity: 0;
        }
      }
    }
  }
}
