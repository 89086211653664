.container {
  width: 100%;
  max-width: size(1416px + 2 * 60px);
  margin: 0 auto;
  padding: 0 size(60px);

  @include vp--1023 {
    padding: 0 24px;
  }

  @include vp--767 {
    padding: 0 16px;
  }

  &--no-padding {
    @include vp--767 {
      padding: 0;
    }
  }
}
